/**
 * Component for Hero
 *
 * @module ui/modules/Hero
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import PaletteHeroTextOnly from 'web-palette/dist/components/HeroTextOnly/HeroTextOnly';
import PaletteHeroFullWidthCompact from 'web-palette/dist/components/HeroFullWidthCompact/HeroFullWidthCompact';
import PaletteHeroFullBleed from 'web-palette/dist/components/HeroFullBleed/HeroFullBleed';
import PaletteHeroHalfImage from 'web-palette/dist/components/HeroHalfImage/HeroHalfImage';
import PaletteHeroShadowBox from 'web-palette/dist/components/HeroShadowbox/HeroShadowbox';

import ActionGroupDefault from '@ulta/core/components/ActionGroupDefault/ActionGroupDefault';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

/**
 * Represents a Hero component
 *
 * @method
 * @param { HeroProps} props - React properties passed from composition
 * @returns Hero
 */
export const Hero = function( props ){
  const { breakpoint } = useDeviceInflection();

  const {
    // Hero variant related props
    variant,
    // Image related props
    image,
    mobileImage,
    tabletImage,
    thumbnailImage,
    // Text related props
    actionGroup,
    body,
    bodySecondLine,
    eyebrow,
    headline,
    headlineTag,
    tag,
    textColor,
    textPosition,
    // Hero styling props
    backgroundColor,
    shadowColor,
    style
  } = props;

  /** Setting each Hero imported in from Palette */
  const PalHeroTextOnly = PaletteHeroTextOnly.default || PaletteHeroTextOnly;
  const PalHeroFullWidthCompact = PaletteHeroFullWidthCompact.default || PaletteHeroFullWidthCompact;
  const PalHeroFullBleed = PaletteHeroFullBleed.default || PaletteHeroFullBleed;
  const PalHeroHalfImage = PaletteHeroHalfImage.default || PaletteHeroHalfImage;
  const PalHeroShadowBox = PaletteHeroShadowBox.default || PaletteHeroShadowBox;

  /** Getting each Hero variant to set which one to display in the return */
  const isTextOnly = variant === 'textOnly';
  const isFullWidthCompact = variant === 'fullWidthCompact';
  const isFullBleed = variant === 'fullBleedHero';
  const isHalfImage = variant === 'halfImage';
  const isShadowBox = variant === 'shadowBox';

  /** Objects for external media and actions coming from Amplience */
  let formattedMedia = {};
  let formattedThumbnail = {};
  let formattedAction = {};

  /** Breakpoint settings for incoming media */
  const isMobile = breakpoint?.isSmallDevice();
  const isDesktop = breakpoint && ( breakpoint.CURRENT_BREAKPOINT === 'LG' || breakpoint.CURRENT_BREAKPOINT === 'XL' );
  const isTablet = breakpoint && ( breakpoint.CURRENT_BREAKPOINT === 'MD' );

  /* TODO: We will need a solution for fully clickable Heroes */
  const isKnockoutLink = ( textColor === 'content-knockout' );

  /** Formatters for incoming media and actions */
  formattedMedia = getUltaAssetHtml( { image: image, mobileImage: mobileImage, tabletImage: tabletImage, isMobile: isMobile, isDesktop: isDesktop, isTablet: isTablet } );
  formattedThumbnail = getUltaAssetHtml( { thumbnailImage: thumbnailImage } );
  formattedAction = getExternalAction( { actionGroup: actionGroup, isKnockoutLink: isKnockoutLink } );

  return (
    <>
      { isTextOnly &&
        <PalHeroTextOnly
          externalAction={ formattedAction }
          backgroundColor={ backgroundColor }
          body={ body }
          externalThumbnailImage={ formattedThumbnail }
          eyebrow={ eyebrow }
          headline={ headline }
          headlineTag={ headlineTag }
          tag={ tag }
          textColor={ textColor }
        />
      }
      { isFullWidthCompact &&
        <PalHeroFullWidthCompact
          externalAction={ formattedAction }
          body={ body }
          bodySecondLine={ bodySecondLine }
          externalMedia={ formattedMedia }
          eyebrow={ eyebrow }
          headline={ headline }
          headlineTag={ headlineTag }
          tag={ tag }
          textColor={ textColor }
          textPosition={ textPosition }
        />
      }
      { isFullBleed &&
        <PalHeroFullBleed
          externalAction={ formattedAction }
          backgroundColor={ backgroundColor }
          body={ body }
          externalMedia={ formattedMedia }
          eyebrow={ eyebrow }
          headline={ headline }
          headlineTag={ headlineTag }
          style={ style }
          tag={ tag }
          textColor={ textColor }
        />
      }
      { isHalfImage &&
        <PalHeroHalfImage
          externalAction={ formattedAction }
          backgroundColor={ backgroundColor }
          body={ body }
          externalMedia={ formattedMedia }
          eyebrow={ eyebrow }
          headline={ headline }
          headlineTag={ headlineTag }
          tag={ tag }
          textColor={ textColor }
        />
      }
      { isShadowBox &&
        <PalHeroShadowBox
          backgroundColor={ backgroundColor }
          body={ body }
          externalAction={ formattedAction }
          externalMedia={ formattedMedia }
          externalThumbnailImage={ formattedThumbnail }
          eyebrow={ eyebrow }
          headline={ headline }
          headlineTag={ headlineTag }
          shadowColor={ shadowColor }
          style={ style }
          tag={ tag }
        />
      }
    </>
  );
};

/**
 * Function for UltaAsset
 * @function getUltaAssetHtml
 * @param { object }  - returns an object with the appropriate HTML markup for UltaAsset depending on screensize
 */
export const getUltaAssetHtml = ( data ) => {
  const {
    image,
    mobileImage,
    tabletImage,
    thumbnailImage,
    isTablet,
    isDesktop,
    isMobile
  } = data;

  /** Need to separate by screensize in order to load a new image when screensize changes */
  return (
    <>
      { isDesktop &&
        <UltaAsset
          { ...image }
        />
      }
      { isTablet &&
        <UltaAsset
          { ...tabletImage }
        />
      }
      { isMobile &&
        <UltaAsset
          { ...mobileImage }
        />
      }
      { thumbnailImage &&
        <UltaAsset
          { ...thumbnailImage }
        />
      }
    </>
  );
};

/**
 * Function for ActionGroupDefault
 * @function getExternalAction
 * @param { object }  - returns an object with the appropriate HTML markup for actionGroup
 */
export const getExternalAction = ( data ) => {
  const {
    actionGroup,
    isKnockoutLink
  } = data;

  return (
    <ActionGroupDefault
      action1={ actionGroup?.action1 }
      action2={ actionGroup?.action2 }
      style={ actionGroup?.style }
      type={ actionGroup?.type }
      isKnockoutLink={ isKnockoutLink }
    />
  )
}

/**
 * Property type definitions
 * @typedef Hero
 * @type {object}
 * @property {object} actionGroup - Allows passing an object to the actionGroup object
 * @property {string} backgroundColor - background color of the hero body content
 * @property {string} body - text/description of the hero body
 * @property {string} bodySecondLine - text/description of the hero bodySecondLine
 * @property {string} eyebrow - smaller text above the title
 * @property {string} headline - title text of the hero body
 * @property {string} headlineTag - headline tag of the hero body
 * @property {object} image - Image component for the desktop view
 * @property {object} mobileImage - Image component for the mobile view
 * @property {object} tabletImage - Image component for the tablet view
 * @property {string} shadowColor - sets the color of the shadow for the ShadowboxHero
 * @property {string} style - sets the style of the Hero
 * @property {object} tag - tag component above the eyebrow
 * @property {string} textColor - sets the color of the text
 * @property {string} textPosition - sets the position of the text
 * @property {object} thumbnailImage - thumbnail image for the textOnlyHero and ShadowboxHero
 * @property {string} variant - sets the variant of the Hero
 */

const propTypes = {
  /**
   * Allows passing an object to the actionGroup object
   */
  actionGroup: PropTypes.object,
  /**
   * background color of the hero body content
   */
  backgroundColor: PropTypes.string,
  /**
   * text/description of the hero body
   */
  body: PropTypes.string,
  /**
   * text/description of the hero bodySecondLine
   */
  bodySecondLine: PropTypes.string,
  /**
   * smaller text above the title
   */
  eyebrow: PropTypes.string,
  /**
   * title text of the hero body
   */
  headline: PropTypes.string,
  /**
   * headline tag of the hero body
   */
  headlineTag: PropTypes.string,
  /**
   * Image component for the desktop view
   */
  image: PropTypes.object,
  /**
   * Image component for the mobile view
   */
  mobileImage: PropTypes.object,
  /**
   * Image component for the tablet view
   */
  tabletImage: PropTypes.object,
  /**
   * sets the color of the shadow for the ShadowboxHero
   */
  shadowColor: PropTypes.string,
  /**
   * sets the style of the Hero
   */
  style: PropTypes.string,
  /**
   * tag component above the eyebrow
   */
  tag: PropTypes.object,
  /**
   * sets the color of the text
   */
  textColor: PropTypes.string,
  /**
   * sets the position of the text
   */
  textPosition: PropTypes.string,
  /**
   * thumbnail image for the textOnlyHero and ShadowboxHero
   */
  thumbnailImage: PropTypes.object,
  /**
   * sets the variant of the Hero
   */
  variant: PropTypes.string
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} headlineTag - headlineTag: 'h2' - sets the default for the headline tag
 */
export const defaultProps =  {
  headlineTag: 'h2'
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;